import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Social from "./social";
import * as bioStyles from "./bio.module.css"
import Img from "gatsby-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            summary
          }
        }
      }
      file(relativePath: {eq: "profilepic.png"}) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const featuredImgFluid = data.file.childImageSharp.fluid

  return (
    <div className= {bioStyles.wrapper}>
      <div className = {bioStyles.left}>
        <h1>
          {author?.summary}
        </h1>
        <Social />
      </div>
      <div className = {bioStyles.right}>
        <Img fluid={featuredImgFluid} className = {bioStyles.profilepic}/>
      </div>
    </div>
  )
}

export default Bio
