import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Img from "gatsby-image"
import * as indexStyles from "./index.module.css"


const PortfolioIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="Portfolio" />
        <Bio />
        <hr />
        <p>
          No case study at the moment... Check back soon!
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Portfolio" />
      <Bio />
      <hr />
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug
          var postCoverImage;
          if (!post.frontmatter.image.childImageSharp && post.frontmatter.image.extension === 'svg') {
            postCoverImage = (
              <div className={indexStyles.coverImgWrapper}>
                <img src={post.frontmatter.image.publicURL}
                     alt={post.frontmatter.title + " Logo"}
                     className={indexStyles.coverImg}/>
              </div>
              )
          } else {
            const featuredImgFluid = post.frontmatter.image.childImageSharp.fluid
            postCoverImage = (<Img fluid={featuredImgFluid} />)
          }

          return (
            <Link to={"work" + post.fields.slug} itemProp="url" className={indexStyles.link}>
            <div className = {indexStyles.row}>
                <div className = {indexStyles.left}>
                    <h1>{title}</h1>
                    <p className = {indexStyles.timeline}> {post.frontmatter.timeline} &middot; {post.frontmatter.shorttitle}</p>
                    <p>{post.frontmatter.subtitle}</p>
                </div>
                <div className = {indexStyles.right}>
                  {postCoverImage}
                </div>
            </div>
            </Link>
          )
        })}
    </Layout>
  )
}

export default PortfolioIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___index], order: ASC }
      filter: {fileAbsolutePath: {regex: "/(portfolio)/"}, frontmatter: {hide: {ne: true}}}
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          shorttitle
          subtitle
          timeline
          image {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
          }
        }
      }
    }
  }
`
